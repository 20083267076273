<template>
  <div>
    <div class="mt-page mb-8">
      <section class="container">
        <div class="profile">
          <div class="profile__info">
            <div class="align-center">
              <Avatar :avatar="user.image" :initials="user.name" type="big" class="radius-6 mr-5" />

              <div>
                <span class="profile__name">{{ user.firstName }} {{ user.lastName }}</span>
                <br />
                <span class="profile__nickname" v-if="user.username">@{{ user.username }}</span>
              </div>
            </div>
            <p class="profile__description">{{ user.description }}</p>

            <div class="profile__info-bottom">
              <button v-if="isCurrentUserPage" class="btn--violet" @click="showForm">
                {{ $t('edit') }}
              </button>

              <div v-else class="profile__info-buttons">
                <button v-if="showSubscribeButton" @click="followUser" class="btn--violet">
                  {{ $t('subscribe') }}
                </button>
                <button v-else @click="unfollowUser" class="btn--violet">
                  {{ $t('subscribed') }}
                </button>

                <a :href="`/app/chat?userId=${user.id}`" class="btn--blue-light">{{
                  $t('send_message')
                }}</a>
              </div>

              <div class="profile__socials">
                <template v-for="{ name, value } of user.socials">
                  <a
                    v-if="value"
                    :href="normalizedLink(name, value)"
                    :key="name"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="btn-social"
                    :class="name"
                  >
                    <component :is="`svg-${name}`" iconColor="#838898" />
                  </a>
                </template>
              </div>
            </div>
          </div>

          <div class="profile__data">
            <h4 class="profile__interests-title">{{ $t('interests') }}</h4>
            <div class="profile__header-interests" v-if="user.interests && user.interests.length">
              <CampusInterest
                v-for="interest in user.interests.slice(0, slicedInterestsCount)"
                :key="interest.id"
                :interest="interest"
              />
            </div>
            <button
              class="button-showmore"
              v-if="isShowMoreButtonVisible"
              @click="onShowMoreButtonClick"
            >
              <span v-if="isShowMoreButtonClicked">{{ $t('show_more') }}</span>
              <span v-else>{{ $t('show_less') }}</span>
            </button>
            <div class="profile__subscriptions">
              <a :href="`/app/user-followers?id=${user.id}`" class="btn--violet mr-3">
                {{ user.followersCount | normalizeCount }}
                {{ $t('subscribers') }}
              </a>

              <a :href="`/app/user-followings?id=${user.id}`" class="btn--blue-light">
                {{ user.followingsCount | normalizeCount }}
                {{ $t('subscriptions') }}
              </a>
            </div>
          </div>
          <button v-if="isCurrentUserPage" class="profile__balance-btn">
            {{ $t('my_income') }} (${{ availableBalance }})
          </button>
        </div>
      </section>

      <transition name="fade">
        <section class="container" v-if="withForm">
          <h2 class="profile__form-title">{{ $t('profile_edition') }}</h2>
          <form class="profile__form" @submit.prevent="updateUserData">
            <AppInput
              v-for="(input, key) of formDataConfig"
              :key="key"
              v-model="input.value"
              :placeholder="$t(input.placeholder)"
              :type="input.type"
            />

            <label class="btn--violet rounded-full condensed"
              >{{ $t('upload_photo') }}
              <input type="file" name="avatar" @change="uploadFile" />
            </label>

            <AppInput
              v-for="(input, key) of formSocialConfig"
              :key="key"
              :placeholder="input.placeholder"
              v-model="input.value"
            >
              <template #icon>
                <component :is="`svg-${key}`" />
              </template>
            </AppInput>
            <button type="submit" class="btn--blue">{{ $t('save_changes') }}</button>
          </form>
        </section>
      </transition>

      <section>
        <ul class="container tabs profile__tabs">
          <!-- <li
            v-for="(tab, key) in tabsConfig"
            :key="key"
            @click="activeTab = key"
            :class="{ active: activeTab === key }"
          >
            <component :is="`svg-${tab.icon}`" class="mr-2" />
            {{ $t(key) }} ({{ tab.pagination.total }})
          </li> -->
          <li
            v-for="(tab, key) in tabsConfig"
            :key="key"
            @click="activeTab = key"
            :class="{ active: activeTab === key }"
          >
            {{ $t(key) }}
          </li>
        </ul>
        <div class="container">
          <p class="profile__empty-text" v-if="tabsConfig[activeTab].items.length === 0">
            {{ $t(tabsConfig[activeTab].emptyStateText) }}
          </p>
          <div class="profile__cards" :class="`profile__cards--${activeTab}`">
            <component
              :is="tabsConfig[activeTab].component"
              v-for="(item, idx) in tabsConfig[activeTab].items"
              :item="item"
              :key="item.id"
              :index="idx"
              :access="isRecordAccess(item)"
              @access-denied="handleRecordAccessDenied"
            />
            <Observer class="dummy" @intersect="handleIntersect"></Observer>
          </div>
        </div>
      </section>
    </div>

    <SubscriptionModal
      v-if="isSubscriptionModalVisible"
      @close="isSubscriptionModalVisible = false"
    />
  </div>
</template>

<script>
import CampusInterest from '../../components/CampusInterest.vue';
import CardCourse from '../../components/CardCourse.vue';
import CardEvent from '../../components/CardEvent.vue';
import CardRecord from '../../components/CardRecord.vue';
import CardCampus from '../../components/CardCampus.vue';
import Observer from '../../components/Observer.vue';
import { formDataConfig, formSocialConfig, tabsConfig } from './config';
import { Vue } from 'vue-property-decorator';
import UserService from '@/api/UserService';
import RecordsService from '@/api/RecordsService';
import FeedService from '@/api/FeedService';
import CoursesService from '@/api/CoursesService';
import SubscriptionModal from '../../components/SubscriptionModal.vue';

export default {
  components: {
    CampusInterest,
    Observer,
    CardRecord,
    CardCourse,
    CardEvent,
    CardCampus,
    SubscriptionModal,
  },
  mounted() {
    if (window.innerWidth < 1024) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }

    window.addEventListener('resize', () => {
      if (window.innerWidth < 1024) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    });
    this.getCurrentUser();
    this.getBalance();
  },

  data() {
    return {
      formDataConfig,
      formSocialConfig,
      records: {
        pagination: {},
        items: [],
      },
      campuses: {
        pagination: {},
        items: [],
      },
      tabsConfig,
      withForm: false,
      activeTab: 'authors_courses',
      files: [],
      user: {},
      balance: null,
      isSubscriptionModalVisible: false,
      currentUser: {},
      isMobile: false,
      slicedInterestsCount: 9,
    };
  },
  computed: {
    availableBalance() {
      return this.balance?.availableBalance;
    },
    userId() {
      let params = new URL(document.location).searchParams;
      let userId = params.get('id');

      return userId;
    },
    isCurrentUserPage() {
      if (+this.userId === this.currentUser?.id) {
        return true;
      } else if (this.userId === null) {
        return true;
      } else {
        return false;
      }
    },
    showSubscribeButton() {
      return this.user?.isFollowed ? false : true;
    },
    isShowMoreButtonClicked() {
      return this.slicedInterestsCount === 9 ? true : false;
    },
    isShowMoreButtonVisible() {
      return this.user?.interests?.length > 9 ? true : false;
    },
    //couldn't figure out how to get access to computed in mounted, created,
    //or other lifecycle hook to initialize activeTab
    // normalizedTabsConfig() {
    //   Object.filter = (obj, predicate) => Object.fromEntries(Object.entries(obj).filter(predicate));

    //   var filteredTabsConfig = Object.filter(
    //     tabsConfig,
    //     ([key, object]) => object.items.length > 0,
    //   );

    //   return filteredTabsConfig;
    // },
  },
  methods: {
    //couldn't figure out how to get access to computed in mounted, created,
    //or other lifecycle hook to initialize activeTab
    // setActiveTab() {
    //   this.activeTab = Object.keys(this.normalizedTabsConfig)[0];
    // },
    async getCurrentUser() {
      await UserService.getUserProfile().then((response) => {
        this.currentUser = response.data;
      });

      this.getUserData();
    },
    async getRecords(nextPage = 1) {
      const { data } = await RecordsService.getUserRecords(this.user.id, nextPage);
      const { pagination, items } = data;

      const newItems = this.tabsConfig.records.items.concat(items);

      Vue.set(this.tabsConfig, 'records', {
        ...this.tabsConfig.records,
        items: newItems,
        pagination,
      });
    },

    async getEvents(nextPage = 1) {
      const { data } = await FeedService.getUserEvents(this.user.id, nextPage);
      const { pagination, items } = data;

      const newItems = this.tabsConfig.events.items.concat(items);

      Vue.set(this.tabsConfig, 'events', {
        ...this.tabsConfig.events,
        items: newItems,
        pagination,
      });
    },

    async getCourses(nextPage = 1) {
      if (this.user?.webId !== null) {
        const { data } = await CoursesService.getUserCourses(this.user?.webId, nextPage);
        const { pagination, items } = data.data;

        const newItems = this.tabsConfig.authors_courses.items.concat(items);

        Vue.set(this.tabsConfig, 'authors_courses', {
          ...this.tabsConfig.authors_courses,
          items: newItems,
          pagination,
        });
      }
    },

    async getCampusesFollower(nextPage = 1) {
      const { data } = await UserService.getForeignCampusesFollower(this.user.id, nextPage);
      const { pagination, items } = data;

      const newItems = this.tabsConfig.campuses_follower.items.concat(items);

      Vue.set(this.tabsConfig, 'campuses_follower', {
        ...this.tabsConfig.campuses_follower,
        items: newItems,
        pagination,
      });
    },

    async getCampusesModerator(nextPage = 1) {
      const { data } = await UserService.getForeignCampusesModerator(this.user.id, nextPage);
      const { pagination, items } = data;

      const newItems = this.tabsConfig.campuses_moderator.items.concat(items);

      Vue.set(this.tabsConfig, 'campuses_moderator', {
        ...this.tabsConfig.campuses_moderator,
        items: newItems,
        pagination,
      });
    },

    async handleIntersect() {
      const { lastPage, currentPage } = this.tabsConfig[this.activeTab].pagination;

      if (lastPage > currentPage) {
        let nextPage = (this.tabsConfig[this.activeTab].pagination.currentPage += 1);

        const METHODS = {
          authors_courses: this.getCourses,
          events: this.getEvents,
          records: this.getRecords,
          campuses_follower: this.getCampusesFollower,
          campuses_moderator: this.getCampusesModerator,
        };
        METHODS[this.activeTab](nextPage);
      }
    },

    uploadFile(evt) {
      this.files.push(evt.target.files[0]);
    },

    async updateUserAvatar() {
      const formData = new FormData();
      formData.append('image', this.files[0]);
      const res = await UserService.updateUserPhoto(formData);
    },

    async getUserData() {
      let data;
      if (this.isCurrentUserPage) {
        this.user = this.currentUser;
      } else {
        await UserService.getForeignUserProfile(this.userId).then((response) => {
          data = response.data;
        });
        this.user = data;
      }

      this.getCourses();
      this.getEvents();
      this.getRecords();
      this.getCampusesFollower();
      this.getCampusesModerator();
    },

    async getUserDataLightened() {
      await UserService.getForeignUserProfile(this.userId).then((response) => {
        let data = response.data;
        this.user = data;
      });
    },

    async updateUserData() {
      const sendData = Object.fromEntries(
        Object.entries({ ...this.formDataConfig }).map(([key, entry]) => [key, entry.value]),
      );

      sendData.socials = Object.fromEntries(
        Object.entries({ ...this.formSocialConfig }).map(([key, entry]) => [
          key,
          { name: key, value: entry.value },
        ]),
      );

      if (this.files.length > 0) {
        await this.updateUserAvatar();
        this.files = [];
      }

      const { data } = await UserService.updateUserProfile(sendData);
      this.user = data;
      this.withForm = false;
    },

    fillForm() {
      this.user.socials.forEach(({ name, value }) => {
        if (name in this.formSocialConfig) {
          const { placeholder } = this.formSocialConfig[name];
          Vue.set(this.formSocialConfig, name, { placeholder, value });
        }
      });
      Object.entries(this.user).forEach(([key, value]) => {
        if (key in this.formDataConfig) {
          Vue.set(this.formDataConfig, key, { ...this.formDataConfig[key], value });
        }
      });
    },

    normalizedLink(name, value) {
      if (name === 'email') {
        return `mailto:${value}`;
      } else if (name === 'instagram') {
        return `https://www.instagram.com/${value}`;
      } else if (name === 'facebook') {
        return `https://facebook.com/${value}`;
      } else if (name === 'website') {
        return `https://${value}`;
      }

      return value;
    },

    async getBalance() {
      if (this.isCurrentUserPage) {
        const { data } = await UserService.getUserBalance();
        this.balance = data;
      }
    },

    showForm() {
      if (this.withForm === true) {
        this.withForm = false;
      } else {
        this.withForm = true;
        this.fillForm();
      }
    },

    handleRecordAccessDenied() {
      this.isSubscriptionModalVisible = true;
    },

    isRecordAccess(record) {
      if (this.activeTab === 'records') {
        if (this.currentUser?.activeSubscription !== null || record.isOwner === true) {
          return true;
        }

        return false;
      }
    },

    async followUser() {
      await UserService.followUser(this.user.id);
      this.getUserDataLightened();
    },

    async unfollowUser() {
      await UserService.unfollowUser(this.user.id);
      this.getUserDataLightened();
    },

    onShowMoreButtonClick() {
      if (this.slicedInterestsCount === 9) {
        this.slicedInterestsCount = 200;
      } else {
        this.slicedInterestsCount = 9;
      }
    },
  },
};
</script>

<style lang="scss" scoped src="./style.scss"></style>
