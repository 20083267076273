export const formDataConfig = {
  firstName: {
    placeholder: 'name',
    value: '',
  },
  lastName: {
    placeholder: 'last_name',
    value: '',
  },
  username: {
    placeholder: 'nickname',
    value: '',
  },
  description: {
    placeholder: 'about_myself',
    value: '',
    type: 'textarea',
  },
};

export const formSocialConfig = {
  facebook: {
    placeholder: 'facebook',
    value: '',
  },
  instagram: {
    placeholder: 'instagram',
    value: '',
  },
  email: {
    placeholder: 'email',
    value: '',
  },
  website: {
    placeholder: 'website',
    value: '',
  },
};

export const tabsConfig = {
  authors_courses: {
    icon: 'antenna',
    pagination: {},
    items: [],
    component: 'CardCourse',
    emptyStateText: 'empty_courses',
  },
  events: {
    icon: 'antenna',
    pagination: {},
    items: [],
    component: 'CardEvent',
    emptyStateText: 'empty_events',
  },
  records: {
    icon: 'headphones',
    pagination: {},
    items: [],
    component: 'CardRecord',
    emptyStateText: 'empty_records',
  },
  campuses_follower: {
    icon: 'dashboard',
    pagination: {},
    items: [],
    component: 'CardCampus',
    emptyStateText: 'empty_campuses_follower',
  },
  campuses_moderator: {
    icon: 'dashboard',
    pagination: {},
    items: [],
    component: 'CardCampus',
    emptyStateText: 'empty_campuses_moderator',
  },
};
