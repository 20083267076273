import { ApiClient } from './ApiClient.js';
const config = {
  transformResponse: [
    function (data) {
      return JSON.parse(data).data;
    },
  ],
};

export default {
  getFeatured() {
    const config = {
      transformResponse: [
        function (data) {
          return JSON.parse(data).data;
        },
      ],
    };

    return ApiClient.get('/feed/featured', config);
  },

  getChatMembers({ chatId, users }) {
    const config = {
      transformResponse: [
        function (data) {
          return JSON.parse(data).data;
        },
      ],
    };

    return ApiClient.post(`chat/${chatId}/members`, { users }, config);
  },

  getUserEvents(id, page = 1) {
    return ApiClient.get(`/feed/soon/${id}?page=${page}`, config);
  },
};
